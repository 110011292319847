import { environment } from 'environments/environment';

export class UserModel {
    role?: string;
    languages?: Array<string>;
    firstName?: string;
    lastName?: string;
    email?: string;

    constructor(props: {
        role?: string;
        languages?: Array<string>;
        firstName?: string;
        lastName?: string;
        email?: string;
    }) {
        const obj = {
            role: props.role ? props.role : '',
            languages:
                environment.appFlavor === 'lga' ||
                environment.appFlavor === 'mea'
                    ? props.languages
                        ? props.languages || ['en']
                        : ['en']
                    : props.languages
                    ? props.languages || ['en', 'es']
                    : ['en', 'es'],
            firstName: props.firstName ? props.firstName : '',
            lastName: props.lastName ? props.lastName : '',
            email: props.email ? props.email : '',
        };

        if (
            environment.appFlavor === 'mia' ||
            environment.appFlavor === 'elpaso'
        ) {
            delete obj.languages;
        }

        return obj;
    }
}

export class UpdateUserModel {
    uid?: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    role?: string;
    languages?: Array<string>;
    disabled?: boolean;

    constructor(props: {
        uid?: string;
        firstName?: string;
        lastName?: string;
        email?: string;
        role?: string;
        languages?: Array<string>;
        disabled?: boolean;
    }) {
        const obj = {
            uid: props.uid ? props.uid : '',
            firstName: props.firstName ? props.firstName : '',
            lastName: props.lastName ? props.lastName : '',
            email: props.email ? props.email : '',
            role: props.role ? props.role : '',
            languages:
                environment.appFlavor === 'lga' ||
                environment.appFlavor === 'mea'
                    ? props.languages
                        ? props.languages || ['en']
                        : ['en']
                    : props.languages
                    ? props.languages || ['en', 'es']
                    : ['en', 'es'],
            disabled: props.disabled ? props.disabled : false,
        };

        if (
            environment.appFlavor === 'mia' ||
            environment.appFlavor === 'elpaso'
        ) {
            delete obj.languages;
        }

        return obj;
    }
}

export class DeleteUserModel {
    userId?: string;

    constructor(props: { userId?: string }) {
        return {
            userId: props.userId || '',
        };
    }
}
