import { Menu } from '@app/models/menu.model';

export const menuConfig: Menu[] = [
    {
        headerTitle: 'MENU.FLIGHTS_INVENTORY',
        roles: ['admin', 'inventory_preparer'],
    },
    {
        title: 'MENU.NEW_INVENTORY',
        path: '/dashboard/content/newInventory',
        icon: 'icofont icofont-ui-clip-board',
        routeName: 'newInventory',
        roles: ['admin', 'inventory_preparer', 'inventory_preparer_admin'],
        name: 'newInventory',
        type: 'link',
        active: false,
    },
    {
        title: 'MENU.INVENTORIES',
        path: '/dashboard/content/inventories',
        icon: 'icofont icofont-list',
        routeName: 'inventories',
        roles: ['admin', 'inventory_preparer', 'inventory_preparer_admin'],
        name: 'inventories',
        type: 'link',
        active: false,
    },
    {
        headerTitle: 'MENU.REQUESTS',
        roles: ['admin', 'callcenter', 'ifsstore', 'lbacc', 'ifshandling'],
    },
    {
        title: 'MENU.NEW',
        path: '/dashboard/content/newFormRequests',
        icon: 'icofont icofont-hour-glass',
        routeName: 'newFormRequests',
        roles: ['admin', 'callcenter', 'ifsstore', 'lbacc', 'ifshandling'],
        name: 'newFormRequests',
        type: 'link',
        active: false,
    },
    {
        title: 'MENU.AWAITING_APPROVAL',
        path: '/dashboard/content/pending',
        icon: 'icofont icofont-clock-time',
        routeName: 'pending',
        roles: ['admin', 'lbacc', 'ifshandling'],
        name: 'pending',
        type: 'link',
        active: false,
    },
    {
        title: 'MENU.APPROVED',
        path: '/dashboard/content/approved',
        icon: 'icofont icofont-check-circled',
        routeName: 'approved',
        roles: ['admin', 'callcenter', 'ifsstore', 'lbacc', 'ifshandling'],
        name: 'approved',
        type: 'link',
        active: false,
    },
    {
        title: 'MENU.DECLINED',
        path: '/dashboard/content/declined',
        icon: 'icofont icofont-thumbs-down',
        routeName: 'declined',
        roles: ['admin', 'callcenter', 'ifsstore', 'lbacc', 'ifshandling'],
        name: 'declined',
        type: 'link',
        active: false,
    },
    {
        title: 'MENU.CANCELED',
        path: '/dashboard/content/canceled',
        icon: 'icofont icofont-ui-block',
        routeName: 'canceled',
        roles: ['admin'],
        name: 'canceled',
        type: 'link',
        active: false,
    },
    {
        headerTitle: 'MENU.CONFIG',
        roles: ['admin'],
    },
    {
        title: 'MENU.USERS',
        path: '/dashboard/content/users',
        icon: 'icofont icofont-people',
        routeName: 'users',
        roles: ['admin'],
        name: 'users',
        type: 'link',
        active: false,
    },
    {
        title: 'MENU.TEMPLATES',
        path: '/dashboard/content/templates',
        icon: 'icofont icofont-file-alt',
        routeName: 'templates',
        roles: ['admin', 'inventory_preparer_admin'],
        name: 'templates',
        type: 'link',
        active: false,
    },
    {
        title: 'MENU.DISTRIBUTION_LIST',
        path: '/dashboard/content/distributionLists',
        icon: 'icofont icofont-email',
        routeName: 'distributionLists',
        roles: ['admin'],
        name: 'distributionLists',
        type: 'link',
        active: false,
    },
];
