import { OperatingHoursRecordsModel } from './mappingModels';

export class NormalizedPoisELPModel {
    key: string;
    name: string;
    category: string;
    mapElementID: string;
    icon?: string;
    locationDescription: string;
    description?: string;
    operatingHours?: Array<OperatingHoursRecordsModel>;

    constructor(props?: NormalizedPoisELPModel) {
        return {
            key: props.key || '',
            name: props.name || '',
            category: props.category || '',
            mapElementID: props.mapElementID || '',
            locationDescription: props.locationDescription || '',
            description: props.description || '',
            operatingHours: props?.operatingHours || [],
        };
    }
}
export class MappedPoisELPModel {
    key: string;
    name: string;
    category: string;
    mapElementID: string;
    icon?: string;
    locationDescription: string;
    description?: string;
    operatingHours?: Array<OperatingHoursRecordsModel>;

    constructor(props?: MappedPoisELPModel) {
        return {
            key: props.key || '',
            name: props.name || '',
            category: props.category || '',
            mapElementID: props.mapElementID || '',
            locationDescription: props.locationDescription || '',
            description: props.description || '',
            icon: props.icon || '',
            operatingHours: props?.operatingHours || [],
        };
    }
}

export class NormalizedHotelsAttractionsELPModel {
    id?: string;
    name?: string;
    description?: {
        en?: string;
        es?: string;
    };
    address?: string;
    location?: string;
    phoneNumber?: string;
    websiteURL?: string;
    rating?: string;
    placeID?: string;
    imageURL?: string;

    constructor(props?: NormalizedHotelsAttractionsELPModel) {
        return {
            id: props.id || '',
            name: props.name || '',
            description: {
                en: props.description?.en || '',
                es: props.description?.es || '',
            },
            address: props.address || '',
            location: props.location || '',
            phoneNumber: props.phoneNumber || '',
            websiteURL: props.websiteURL || '',
            rating: props.rating || '',
            placeID: props.placeID || '',
        };
    }
}

export class MappedHotelsAttractionsELPModel {
    id?: string;
    name?: string;
    description?: {
        en?: string;
        es?: string;
    };
    address?: string;
    location?: string;
    phoneNumber?: string;
    websiteURL?: string;
    rating?: string;
    placeID?: string;
    imageURL?: string;

    constructor(props?: MappedHotelsAttractionsELPModel) {
        return {
            id: props.id || '',
            name: props.name || '',
            description: {
                en: props.description?.en || '',
                es: props.description?.es || '',
            },
            address: props.address || '',
            location: props.location || '',
            phoneNumber: props.phoneNumber || '',
            websiteURL: props.websiteURL || '',
            rating: props.rating || '',
            placeID: props.placeID || '',
            imageURL: props.imageURL || '',
        };
    }
}
