export const environment = {
    pageName: 'MEA CMS',
    appFlavor: 'mea',
    production: false,
    appEnv: 'qa',
    googleMapsKey: '',
    apiConfig: {
        projectEndpoint: 'https://mea-api-qa.itxi.aero',
        apiKey: '8721Td-5Gdr-2GtsA-H6Dv!-6532HYv',
    },
    firebase: {
        apiKey: 'AIzaSyAUZbnzyvyEonhTfU7VZRd1yKih1nr3UaQ',
        authDomain: 'mea-cabincrew-portal-qa.firebaseapp.com',
        databaseURL: 'https://mea-cabincrew-portal-qa.firebaseio.com',
        projectId: 'mea-cabincrew-portal-qa',
        storageBucket: 'mea-cabincrew-portal-qa.appspot.com',
        messagingSenderId: '10117210974',
        appId: '1:10117210974:web:89cfbef6ab710abc2025cf',
        measurementId: 'G-6TFL30K9ZZ',
    },
    googleAnalyticsTrackId: '',
    rolesWithLoginAccess: [
        {
            role: 'admin',
            route: 'dashboard/content/newFormRequests',
            node: 'newFormRequests',
        },
        {
            role: 'callcenter',
            route: 'dashboard/content/newFormRequests',
            node: 'newFormRequests',
        },
        {
            role: 'ifsstore',
            route: 'dashboard/content/newFormRequests',
            node: 'newFormRequests',
        },
        {
            role: 'lbacc',
            route: 'dashboard/content/newFormRequests',
            node: 'newFormRequests',
        },
        {
            role: 'ifshandling',
            route: 'dashboard/content/newFormRequests',
            node: 'newFormRequests',
        },
        {
            role: 'inventory_preparer',
            route: 'dashboard/content/newInventory',
            node: 'templates',
        },
        {
            role: 'inventory_preparer_admin',
            route: 'dashboard/content/newInventory',
            node: 'templates',
        },
    ],
    sessionTokenCheckInterval: 300000,
    getAllFlightsListInterval: 300000,
    sessionTokenExtension: true,
    defaultRoute: 'dashboard/content/newFormRequests',
    enableRandomBackground: false,
    isGoogleAnalyticEnabled: false,
    airportCoordinates: {
        latitude: 0,
        longitude: 0,
        ISO3166_1_Alpha_2: 'LB',
    },
    videoCategories: [],
    userTableLanguages: [{ key: 'en', label: 'LANGUAGES.ENGLISH_ONLY' }],
    userTablesRole: [
        { key: 'admin', label: 'ROLES.ADMIN' },
        { key: 'callcenter', label: 'ROLES.CALLCENTER' },
        { key: 'ifsstore', label: 'ROLES.IFSSTORE' },
        { key: 'lbacc', label: 'ROLES.LBCC' },
        { key: 'ifshandling', label: 'ROLES.IFSHANDLING' },
        { key: 'inventory_preparer', label: 'ROLES.INVENTORY_PREPARER' },
        { key: 'inventory_dispatcher', label: 'ROLES.INVENTORY_DISPATCHER' },
        {
            key: 'inventory_preparer_admin',
            label: 'ROLES.INVENTORY_PREPARER_ADMIN',
        },
    ],
    formLanguages: [],
    appCategories: [],
    payment_method: ['Cash', 'Credit Card', 'Complimentary'],
    payment_status: ['Received', 'Pending'],
    orientationArray: [],
    containerType: [
        { key: 'CARRIER', label: 'CONTAINER_TYPE.CARRIER' },
        { key: 'CART-HALF', label: 'CONTAINER_TYPE.CART_HALF' },
        { key: 'CART', label: 'CONTAINER_TYPE.CART' },
        { key: 'BOX', label: 'CONTAINER_TYPE.BOX' },
        {
            key: 'EXTRA_CUTLERY',
            label: 'CONTAINER_TYPE.EXTRA_CUTLERY',
        },
    ],
    contentType: [
        { key: 'EQUIPMENT', label: 'CONTENT_TYPE.EQUIPMENT' },
        { key: 'MEAL', label: 'CONTENT_TYPE.MEAL' },
        { key: 'ICE', label: 'CONTENT_TYPE.ICE' },
        { key: 'BULK', label: 'CONTENT_TYPE.BULK' },
        { key: 'CONSUMABLES', label: 'CONTENT_TYPE.CONSUMABLES' },
    ],
    contentItems: [
        {
            key: 'S1BCH',
            label: 'Hot meal for business class',
        },
        {
            key: 'S1EYH',
            label: 'Hot Meal for economy class',
        },
        {
            key: 'S1CRH',
            label: 'Hot Meal for cabin crew',
        },
        {
            key: 'S1CPTH',
            label: 'Hot Meal for captain',
        },
        {
            key: 'S1COPH',
            label: 'Hot Meal for co-pilot',
        },
    ],
    equipmentType: [
        {
            key: 'A332',
            label: 'Airbus A330-200',
        },
        {
            key: 'A320',
            label: 'Airbus A320',
        },
        {
            key: 'A321N',
            label: 'Airbus A321neo',
        },
        {
            key: 'A321',
            label: 'Airbus A321',
        },
    ],
    departureTime: [
        {
            key: 'morning',
            label: 'Morning',
        },
        {
            key: 'afternoon',
            label: 'Afternoon',
        },
        {
            key: 'evening',
            label: 'Evening',
        },
        {
            key: 'night',
            label: 'Night',
        },
    ],
    sectors: [
        {
            key: 'ABJ',
            label: 'ABJ',
        },
        {
            key: 'ACC',
            label: 'ACC',
        },
        {
            key: 'AMM',
            label: 'AMM',
        },
        {
            key: 'AMS',
            label: 'AMS',
        },
        {
            key: 'ATH',
            label: 'ATH',
        },
        {
            key: 'AUH',
            label: 'AUH',
        },
        {
            key: 'BEY',
            label: 'BEY',
        },
        {
            key: 'BGW',
            label: 'BGW',
        },
        {
            key: 'BRU',
            label: 'BRU',
        },
        {
            key: 'BSR',
            label: 'BSR',
        },
        {
            key: 'CAI',
            label: 'CAI',
        },
        {
            key: 'CDG',
            label: 'CDG',
        },
        {
            key: 'CPH',
            label: 'CPH',
        },
        {
            key: 'DMM',
            label: 'DMM',
        },
        {
            key: 'DOH',
            label: 'DOH',
        },
        {
            key: 'DUS',
            label: 'DUS',
        },
        {
            key: 'DXB',
            label: 'DXB',
        },
        {
            key: 'EBL',
            label: 'EBL',
        },
        {
            key: 'EVN',
            label: 'EVN',
        },
        {
            key: 'FCO',
            label: 'FCO',
        },
        {
            key: 'FRA',
            label: 'FRA',
        },
        {
            key: 'GOT',
            label: 'GOT',
        },
        {
            key: 'GVA',
            label: 'GVA',
        },
        {
            key: 'IST',
            label: 'IST',
        },
        {
            key: 'JED',
            label: 'JED',
        },
        {
            key: 'KAN',
            label: 'KAN',
        },
        {
            key: 'KWI',
            label: 'KWI',
        },
        {
            key: 'LCA',
            label: 'LCA',
        },
        {
            key: 'LHR',
            label: 'LHR',
        },
        {
            key: 'LOS',
            label: 'LOS',
        },
        {
            key: 'MAD',
            label: 'MAD',
        },
        {
            key: 'MED',
            label: 'MED',
        },
        {
            key: 'MXP',
            label: 'MXP',
        },
        {
            key: 'NCE',
            label: 'NCE',
        },
        {
            key: 'NJF',
            label: 'NJF',
        },
        {
            key: 'RUH',
            label: 'RUH',
        },
        {
            key: 'SSH',
            label: 'SSH',
        },
        {
            key: 'SXF',
            label: 'SXF',
        },
        {
            key: 'LDE',
            label: 'LDE',
        },
        {
            key: 'CTA',
            label: 'CTA',
        },
        {
            key: 'BCN',
            label: 'BCN',
        },
        {
            key: 'LIS',
            label: 'LIS',
        },
        {
            key: 'OPO',
            label: 'OPO',
        },
        {
            key: 'FNC',
            label: 'FNC',
        },
        {
            key: 'PXO',
            label: 'PXO',
        },
        {
            key: 'BUD',
            label: 'BUD',
        },
        {
            key: 'DBV',
            label: 'DBV',
        },
        {
            key: 'JMK',
            label: 'JMK',
        },
        {
            key: 'JTR',
            label: 'JTR',
        },
        {
            key: 'AOK',
            label: 'AOK',
        },
        {
            key: 'CFU',
            label: 'CFU',
        },
        {
            key: 'CHQ',
            label: 'CHQ',
        },
        {
            key: 'EFL',
            label: 'EFL',
        },
        {
            key: 'GPA',
            label: 'GPA',
        },
        {
            key: 'HER',
            label: 'HER',
        },
        {
            key: 'JSI',
            label: 'JSI',
        },
        {
            key: 'KLX',
            label: 'KLX',
        },
        {
            key: 'KGS',
            label: 'KGS',
        },
        {
            key: 'KVA',
            label: 'KVA',
        },
        {
            key: 'LXS',
            label: 'LXS',
        },
        {
            key: 'MJT',
            label: 'MJT',
        },
        {
            key: 'PVK',
            label: 'PVK',
        },
        {
            key: 'RHO',
            label: 'RHO',
        },
        {
            key: 'SMI',
            label: 'SMI',
        },
        {
            key: 'VOL',
            label: 'VOL',
        },
        {
            key: 'ZTH',
            label: 'ZTH',
        },
        {
            key: 'SXB',
            label: 'SXB',
        },
        {
            key: 'MRS',
            label: 'MRS',
        },
        {
            key: 'VIE',
            label: 'VIE',
        },
        {
            key: 'DWC',
            label: 'DWC',
        },
        {
            key: 'ADB',
            label: 'ADB',
        },
        {
            key: 'ADA',
            label: 'ADA',
        },
        {
            key: 'SAW',
            label: 'SAW',
        },
        {
            key: 'ULH',
            label: 'ULH',
        },
        {
            key: 'TUN',
            label: 'TUN',
        },
        {
            key: 'COO',
            label: 'COO',
        },
        {
            key: 'CMN',
            label: 'CMN',
        },
        {
            key: 'ALG',
            label: 'ALG',
        },
        {
            key: 'RTM',
            label: 'RTM',
        },
    ],
    cartItems: [
        'S1BC',
        'S1FC',
        'S1W',
        'S1EY',
        'S1CR',
        'S1CPT',
        'S1COP',
        'S2BC',
        'S2FC',
        'S2W',
        'S2EY',
        'S2CR',
        'S2CPT',
        'BASE PLATE',
        'BREWER',
        'CHINA CUP+SAUCER',
        'COFFE POT',
        'CUTLERY',
        'DESSERT SPOON',
        'ESPRESSO CUP',
        'ICE BUCKET',
        'JUICE POT',
        'LONG GLASS',
        'MEAL FORK',
        'MEAL KNIVE',
        'MILK JUG',
        'MOCCA SPOON',
        'MUG',
        'PLASTIC DRAWER',
        'PLASTIC EXT.',
        'SAUCERS',
        'SERVING TRAY',
        'SIDE PLATE',
        'SILVER TRAY',
        'SNACK TRAY',
        'TEA POT',
        'TEA SET',
        'WINE GLASS',
        'Y/C KNIFE',
        'Y/C SPOON',
        'NAPKIN',
        'BAG',
        'PLASTIC CUP',
        'EYESHADES',
        'PAPER BAG',
    ],
    tags: [],
};
